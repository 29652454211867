













import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';
import { ActionInterface } from '@/types/breadcrumb';
import { FormComponent } from '@/types/v-form';

import ProRiskForm from '@modules/professional-risk/components/form/ProRiskForm.vue';
import ProRiskList from '@modules/professional-risk/components/ProRiskList.vue';

import Risk from '@models/proRisk/Risk';
import ProFolder from '@models/proRisk/ProFolder';

const firmModule = namespace('firm');
const riskProModule = namespace('riskPro');

@Component({
  components: {
    Layout,
    ProRiskForm,
    ProRiskList,
  },
})
export default class RiskIdentificationView extends Vue {
  risk: Risk | null = null;

  @riskProModule.Getter('getActiveProFolder')
  getActiveProFolder!: ProFolder | null;

  loading: boolean = false;

  $refs!: {
    riskForm: FormComponent
  };

  get actions(): Array<ActionInterface> {
    return [
      {
        label: 'Enregistrer et ajouter',
        handler: () => {
          this.loading = true;
          this.$refs.riskForm.valid()
            .then(() => {
              this.risk = this.createNewRisk();
            })
            .finally(() => {
              this.loading = false;
            });
        },
        condition: () => !this.loading,
      },
      {
        label: 'Enregistrer et revenir',
        handler: () => {
          this.loading = true;
          this.$refs.riskForm.valid()
            .then(() => {
              this.$router.push({ name: 'pro-risks-identification' });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        condition: () => !this.loading,
      },
    ];
  }

  @Watch('$route.params.id', { immediate: true })
  onRouteChange(id: number | null | undefined) {
    if (id !== null && id !== undefined) {
      this.risk = Risk.query().with(['structures', 'folder']).find(id) as Risk;
    } else {
      this.risk = this.createNewRisk();
    }
  }

  createNewRisk() {
    const risk = new Risk();
    const folder = this.getActiveProFolder;
    if (folder !== null) {
      risk.folder = folder;
    }

    return risk;
  }
}
