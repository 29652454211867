



































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { RiskDropdownArgs } from '@modules/professional-risk/types/risk';

import Risk from '@models/proRisk/Risk';

const firmModule = namespace('firm');

const riskRepository = namespace('repositories/risk');

@Component
export default class ProRiskList extends Vue {
  @riskRepository.Action('delete')
  delete!: (id: number) => void;

  @riskRepository.Getter('getAll')
  risks!: Risk[];

  handleDropDown(args: RiskDropdownArgs) {
    if (args.type === 'update') {
      this.$router.push({
        name: 'pro-risks-identification-update',
        params: { id: args.risk.id.toString() },
      });
    } else if (args.type === 'duplicate') {
      console.log('duplicate');
    } else if (args.type === 'remove') {
      this.$confirm('Confirmer la supression du risque.').then((result) => {
        if (result === 'confirm') {
          this.delete(args.risk.id);
        }
      });
    }
  }
}
